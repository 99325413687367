import React from 'react'

import styles from './press.module.css'
import '../style.module.css'

import Skeleton from '../components/Skeleton'
import Row from '../components/common/Row'
import PressPreview from '../components/PressPreview'

import config from '../config'

class Press extends React.Component {
  render() {
    const {
      press: {
        pressPreviews,
        bannerImage,
        customHero
      }
    } = config


    return (
      <Skeleton 
        disableFooterImage={true}
        bannerImage={bannerImage}
        customHero={customHero}
      >
          <Row className={styles.pressContainer}>
              {
                pressPreviews.map((pressPreviewData, index) => (
                    <PressPreview 
                      {...pressPreviewData} 
                    />
                  )
                )
              }
          </Row>
      </Skeleton>
    )
  }
}

export default Press
