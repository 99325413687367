import React from 'react';

import styles from './PressPreview.module.css'

import Row from '../common/Row'
import CTA from '../common/CTA';

const PressInfo = ({
    title,
    description,
    ctaLink,
    ctaText
}) => (
    <div className={styles.infoContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <CTA href={ctaLink} className={styles.cta} target="_blank">
            {ctaText}
        </CTA>
    </div>
)

const PressPreview = (props) => {
    const { 
        img: {
            src: imgSrc,
            alt: imgAlt,
        },
        title,
        description,
        cta: {
            href: ctaLink,
            text: ctaText
        }
    } = props;

    return (
        <Row className={styles.pressPreviewContainer}>
                <Row.LeftColumn>
                    <a 
                        className={styles.linkContainer}
                        target="_blank"
                        rel="noreferrer"
                        href={ctaLink}>
                        <img 
                            className={styles.pressImage}
                            src={imgSrc}
                            alt={imgAlt}
                            height={462}
                            width={520}
                            loading="lazy" />
                    </a>
                </Row.LeftColumn>
                <Row.RightColumn>
                    <PressInfo
                        title={title}
                        description={description}
                        ctaLink={ctaLink}
                        ctaText={ctaText}
                    />
                </Row.RightColumn>
        </Row>
    )
}

export default PressPreview