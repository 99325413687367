import React from 'react';
import styles from './Row.module.css'

const Row = ({ children, className = '' }) => (
    <div className={`${styles.row} ${className}`}>
        {children}
    </div>
)

const LeftColumn = ({children}) => (
    <div className={styles.leftColumn}>
        {children}
    </div>
)
const RightColumn = ({children}) => (
    <div className={styles.rightColumn}>
        {children}
    </div>
)

Row.LeftColumn = LeftColumn;
Row.RightColumn = RightColumn;

export default Row