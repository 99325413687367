import React from 'react';

import styles from './CTA.module.css'

const CTA = ({
    href,
    children,
    className = '',
    target='_self'
}) => (
    <a
        target={target}
        className={`${styles.cta} ${className}`}
        href={href}
    >
        {children}
    </a>
)
export default CTA;